<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员信息审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl"
          style="display: flex; flex-direction: column; align-items: flex-start"
        >
          <div class="searchbox">
            <div title="姓名" class="searchboxItem">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                clearable
                size="small"
                placeholder="请输入姓名"
              />
            </div>
            <div title="身份证号" class="searchboxItem">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="idcard"
                type="text"
                clearable
                size="small"
                placeholder="请输入身份证号"
              />
            </div>
            <div title="手机号" class="searchboxItem">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="mobile"
                type="text"
                clearable
                size="small"
                placeholder="请输入手机号"
              />
            </div>
            <div title="实名认证" class="searchboxItem">
              <span class="itemLabel">实名认证:</span>
              <el-select
                v-model="authentication"
                clearable
                placeholder="请选择认证状态"
                size="small"
              >
                <el-option
                  v-for="item in authenticationList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
                <!-- <el-option  label="已认证" value="20"></el-option>
                <el-option label="未认证" value="10"></el-option>-->
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top: 15px">
            <div title="账号状态" class="searchboxItem">
              <span class="itemLabel">账号状态:</span>
              <el-select
                v-model="state"
                clearable
                placeholder="请选择账号状态"
                size="small"
              >
                <el-option
                  v-for="item in stateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
                <!-- <el-option label="正常" value="10"></el-option>
                <el-option label="停用" value="20"></el-option>
                <el-option label="注销" value="30"></el-option>-->
              </el-select>
            </div>
            <div title="证件类型" class="searchboxItem">
              <span class="itemLabel">证件类型:</span>
              <el-select
                v-model="idType"
                clearable
                placeholder="请选择证件类型"
                size="small"
              >
                <el-option
                  v-for="item in idTypeList"
                  :key="item.id"
                  :label="item.table"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="mergeAccountBNumber()"
                >账号合并</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                width="80"
              />
              <el-table-column
                label="证件类型"
                align="left"
                prop="idType"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                {{$setDictionary('EDU_USER_CARD_TYPE',scope.row.idType)}}
              </template>
              </el-table-column>
              <el-table-column
                label="证件号码"
                align="left"
                prop="idcard"
                show-overflow-tooltip
                width="200"
              />
              <el-table-column
                label="手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
              />
              <el-table-column
                label="所属企业"
                align="left"
                prop="compName"
                show-overflow-tooltip
              />
              <el-table-column
                label="部门"
                align="left"
                prop="department"
                show-overflow-tooltip
              />
              <el-table-column label="实名认证" align="center">
                <span slot-scope="scope">
                  {{
                    $setDictionary("AUTHENTICATION", scope.row.authentication)
                  }}
                </span>
              </el-table-column>
              <el-table-column label="账号状态" align="center">
                <span slot-scope="scope">
                  {{ $setDictionary("USERSTATE", scope.row.state) }}
                </span>
              </el-table-column>
              <el-table-column label="审核状态" align="center">
                <span slot-scope="scope">
                  {{ scope.row.audited === true ? "已通过" : (scope.row.audited === false ? "已驳回": "待审核") }}
                </span>
              </el-table-column>
              <el-table-column label="备注" align="left" prop="remark" show-overflow-tooltip width="200" >
              </el-table-column>
              <el-table-column label="操作" align="center" width="220px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.state == '30'"
                    @click="changeStatus(scope.row.state, scope.row.userId)"
                  >
                    {{ scope.row.state === "20" ? "启用" : "禁用" }}
                  </el-button>
                  <el-button
                      v-if="!scope.row.audited"
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleAudit(scope.row.userId,scope.row.authentication)"
                    >审核</el-button
                  >
                  <el-button
                      v-else
                    :disabled="scope.row.state == '30'"
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleEdit(scope.row.userId,scope.row.authentication)"
                    >编辑</el-button
                  >
                  <el-button
                    :disabled="
                      scope.row.authentication === '20' ||
                      scope.row.state == '30'
                    "
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleDel(scope.row.userId)"
                    >删除</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleResetPass(scope.row.userId)"
                    >重置密码</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
        title="审核学员"
        :visible.sync="dialogVisibleAudit"
        width="30%"
        @close="handleCloseAudit"
    >
      <div>
        <el-form
            ref="auditForm"
            label-width="8rem"
            label-position="left"
            class="form"
            :rules="auditRules"
            :model="auditForm"
        >
          <el-form-item label="是否审核通过:" prop="audited">
            <el-select v-model="auditForm.audited" placeholder="请选择" size="small" >
              <el-option
                  label="是"
                  :value="true"
              ></el-option>
              <el-option
                  label="否"
                  :value="false"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <el-input
                type="textarea"
                placeholder="请输入备注"
                v-model="auditForm.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <span style="display: flex; justify-content: flex-end">
          <el-button @click="handleCloseAudit">取 消</el-button>
          <el-button class="bgc-bv" @click="auditUser()"
          >确 定</el-button
          >
        </span>
      </div>
    </el-dialog>
    <el-dialog
      title="合并账号"
      :visible.sync="dialogVisibleNum"
      width="30%"
      @close="handleClose"
    >
      <div>
        <p style="color: red; padding-bottom: 1rem">
          本功能只为机构报错手机号,学员本身手机号已有购买记录无法删除使用
        </p>
        <el-form
          ref="baseform"
          label-width="8rem"
          label-position="left"
          class="form"
          :rules="rules"
          :model="baseform"
        >
          <el-form-item label="学员身份证号:" prop="idcard">
            <el-input
              v-model="baseform.idcard"
              size="small"
              @input="
                (val) => {
                  getIdcard(val);
                }
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="学员实际手机号:" prop="phone">
            <el-input v-model="baseform.phone" size="small"></el-input>
          </el-form-item>
        </el-form>
        <span style="display: flex; justify-content: flex-end">
          <el-button @click="handleClose">取 消</el-button>
          <el-button class="bgc-bv" @click="mergeAccountSure('baseform')"
            >确 定</el-button
          >
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "studentsList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userName: "",
      idcard: "",
      mobile: "",
      deletedstate: "true",
      userstate: "",
      authentication: "",
      state: "",
      idType:'',
      authenticationList: [], //实名认证list
      stateList: [], //账号状态
      // 证件类型 - 下拉数据
      idTypeList: [
        {
          id: "10",
          table: "身份证",
        },
        {
          id: "20",
          table: "外籍护照",
        },
      ],
      dialogVisibleNum: false,
      dialogVisibleAudit: false,
      auditForm: {
        userId: null,
        audited: false,
        remark: null,
      },
      baseform: {
        idcard: "",
        phone: "",
      },
      rules: {
        idcard: [
          { required: true, trigger: "blur", validator: this.$validateIdCard },
        ],
        phone: [
          {
            required: true,
            validator: this.$validatePhone,
            trigger: "blur",
          },
        ],
      },
      auditRules: {
        audited: [
          { required: true, trigger: "change" },
        ],
        remark: [
          {
            trigger: "blur",
            max: 200,
            message: "审核备注不能超过200个字"
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.getAuthenticationList();
    this.getStateList();
  },
  methods: {
    auditUser() {
      this.$refs['auditForm'].validate((valid) => {
        if (valid) {
          if (!this.auditForm.audited && !this.auditForm.remark) {
            this.$message({
              type: "error",
              message: "审核通过时备注不能为空",
            });
            return;
          }
          this.$post("/run/user/audit", this.auditForm).then((ret) => {
            if (ret.status == 0) {
              this.$message({
                type: "success",
                message: ret.message,
              });
              this.handleCloseAudit();
              this.getData(-1)
            }
          });
        }
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userName: this.userName || null,
        idcard: this.idcard || null,
        mobile: this.mobile || null,
        authentication: this.authentication || null,
        state: this.state || null,
        idType: this.idType || null,
      };
      this.doFetch({
        url: "/run/user/queryUserList",
        params,
        pageNum,
      });
    },
    //实名认证
    getAuthenticationList() {
      const studylist = this.$setDictionary("AUTHENTICATION", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      this.authenticationList = list;
    },
    //账号状态
    getStateList() {
      const studylist = this.$setDictionary("USERSTATE", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      this.stateList = list;
    },
    //启用禁用
    changeStatus(state, id) {
      if (state == "10") {
        this.userstate = 20;
      } else if (state == "20") {
        this.userstate = 10;
      }
      this.$post("/run/user/disableUser", {
        state: this.userstate,
        userId: id,
      }).then((ret) => {
        this.getData(-1);
      });
    },
    handleAudit(id,authentication) {
      let dataQuery = JSON.stringify({
        id,
        authenticationDisable:authentication == '10'? false:true,
      })
      this.$router.push({
        path: "/web/auditStudent",
        query: {
          dataQuery
        },
      });
    },
    //编辑
    handleEdit(id,authentication) {
      let dataQuery = JSON.stringify({
        id,
        authenticationDisable:authentication == '10'? false:true,
      })
      this.$router.push({
        path: "/web/editStudent",
        query: {
        dataQuery,
          sourcePath: "/web/studentsListAudit"
        },
      });
    },
    // 删除
    handleDel(id) {
      this.doDel({
        title: "删除",
        url: "/run/user/deleteUser",
        msg: "是否确认删除该学员信息",
        ps: {
          type: "post",
          data: { userId: id },
        },
      });
    },
    //合并账号
    mergeAccountBNumber() {
      this.dialogVisibleNum = true;
    },
    getIdcard(newValue) {
      if (newValue.length == 18) {
        this.$post("/biz/user/queryUserByIdCard", {
          idcard: this.baseform.idcard,
        })
          .then((res) => {
            this.userNames = res.data.userName;
            // if (!res.data) {
            //   this.showtip = false;
            // } else {
            //   this.showtip = true;
            //   this.idcardObj = res.data;
            //   this.idcardmation =
            //     res.data.userName +
            //       "/" +
            //       res.data.idcard +
            //       "/" +
            //       res.data.mobile || "";
            // }
          })
          .catch((err) => {
            return;
          });
      }
    },
    mergeAccountSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(
            "你确定要将学员:" +
              this.userNames +
              "(" +
              "身份证号:" +
              this.baseform.idcard +
              ")" +
              "与学员实际手机号:" +
              this.baseform.phone +
              "进行账号合并？",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
            }
          )
            .then(() => {
              this.$post("/run/user/merge/user", {
                idcard: this.baseform.idcard,
                mobile: this.baseform.phone,
              })
                .then((ret) => {
                  if (ret.status == 0) {
                    this.$message({
                      type: "success",
                      message: ret.message,
                    });
                    this.handleClose();
                  }
                })
                .catch((err) => {
                  return;
                });
            })
            .catch(() => {
              this.handleClose();
            });
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.dialogVisibleNum = false;
      this.$refs["baseform"].resetFields();
    },
    handleCloseAudit() {
      this.dialogVisibleAudit = false;
      this.$refs["auditForm"].resetFields();
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 2.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    handleResetPass(userId) {
        let confirmText = [
        "是否将用户密码重置？",
        "重置后，变为初始密码Aa@123456",
      ];
      const newData = [];
      const h = this.$createElement;
      for (const i in confirmText) {
        newData.push(h("p", null, confirmText[i]));
      }
      this.$confirm("提示", {
        title: "提示",
        message: h("div", null, newData),
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/run/user/reset/password", {
            userId,
          }).then((ret) => {
            if (ret.status == 0) {
              this.$message({
                type: "success",
                message: "重置密码成功",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置密码",
          });
        });
    
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
// .el-table--border::after, .el-table--group::after{
//   height: 0;
// }
// .el-table--enable-row-transition .el-table__body td:first-child{
//   border-left: 1px solid #EBEEF5;
// }
// .el-table--border, .el-table--group{
//   border: 0;
// }
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>
